import React, {useCallback, useEffect, useRef, useState} from 'react'
import {
  Image as ImageRN,
  PixelRatio,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import ViewShot from 'react-native-view-shot'
import {LinearGradient} from 'expo-linear-gradient'
import {Trans} from '@lingui/macro'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {IShareTea, useModalControls} from '#/state/modals'
import {LogoHorizontal} from '#/view/icons/LogoHorizontal'
import {QuotationIcon} from '#/view/icons/ModalIcons'
import {atoms as a, useTheme} from '#/alf'
import {IconCircle} from '#/components/IconCircle'
import {Close_Stroke2_Corner2_Rounded as Close} from '#/components/icons/Close'
import {UserAvatar} from '../../util/UserAvatar'
import {ShareQRCode} from './ShareQRCode'
// import {getCanvas} from './ShareProfile'

export const snapPoints = ['fullscreen']

export function Component({
  avatar,
  displayName,
  url,
  image,
  title,
  content,
  target,
}: IShareTea) {
  const viewRef = useRef<ViewShot>(null)
  const titleRef = useRef<Text>(null)
  const contentRef = useRef<Text>(null)

  const {closeModal} = useModalControls()
  const t = useTheme()
  const pixelRatio = PixelRatio.get()
  const {isMobile} = useWebMediaQueries()

  const [imageUrl, setImageUrl] = useState('')
  const [postImageUrl, setPostImageUrl] = useState('')
  const [shareImageUri, setShareImageUri] = useState<string | undefined>(
    undefined,
  )
  const [imageDimensions, setImageDimensions] = useState({width: 0, height: 0})
  const [isRendered, setIsRendered] = useState<boolean>(false)

  const titleText = countCharacters(title, 60)
  const contentText = countCharacters(
    content.replaceAll('\n', ' '),
    title !== '' ? 130 : 98,
  )
  const targetText = countCharacters(target, 35)

  const captureView = useCallback(async () => {
    try {
      if (shareImageUri || !viewRef.current) return
      viewRef.current?.capture?.().then(async (uri: string) => {
        // const canvas = await getCanvas(uri)
        // const imgHref = canvas
        //   .toDataURL('image/png')
        //   .replace('image/png', 'image/octet-stream')
        setShareImageUri(uri)
        ImageRN.getSize(uri, (width, height) => {
          setImageDimensions({
            width: width / pixelRatio,
            height: height / pixelRatio,
          })
        })
      })
      // const imgUri = await captureRef(viewRef, {format: 'png', quality: 1.0})
    } catch (error) {
      console.error('Failed to capture view', error)
    }
  }, [pixelRatio, shareImageUri])

  useEffect(() => {
    const fetchImage = async () => {
      try {
        if (avatar) {
          const response = await fetch(avatar)
          const blob = await response.blob()
          const imgUrl = URL.createObjectURL(blob)
          setImageUrl(imgUrl)
        }

        if (image) {
          const bgResponse = await fetch(image)
          const bgBlob = await bgResponse.blob()
          const bgUrl = URL.createObjectURL(bgBlob)
          setPostImageUrl(bgUrl)
        }
      } catch (error) {
        console.error('Error fetching image:', error)
      } finally {
        setIsRendered(true)
      }
    }

    fetchImage()
  }, [avatar, image])

  useEffect(() => {
    if (viewRef?.current && isRendered) {
      captureView()
    }
  }, [viewRef, captureView, isRendered])

  return (
    <View>
      <TouchableOpacity
        accessibilityRole="button"
        style={{
          position: 'absolute',
          right: 20,
          top: -30,
        }}
        onPress={closeModal}>
        <IconCircle
          style={[
            {
              width: 20,
              height: 20,
              borderRadius: 10,
              backgroundColor: t.palette.white,
            },
          ]}
          icon={Close}
          iconStyle={{color: t.palette.black}}
          size="xs"
        />
      </TouchableOpacity>
      <View style={styles.out}>
        <ViewShot
          ref={viewRef}
          options={{quality: 1.0}}
          style={[styles.viewShot, shareImageUri ? {display: 'none'} : {}]}>
          <LinearGradient
            colors={['#DBFE0A', '#FCD010']}
            style={styles.outputBox}>
            <View style={styles.userInfo}>
              <View
                style={{
                  borderWidth: 2,
                  borderRadius: 100,
                  width: 32,
                  height: 32,
                }}>
                <UserAvatar avatar={imageUrl} size={28} />
              </View>
              <View>
                <Text
                  style={[a.text_md, a.font_heavy, {color: t.palette.black}]}>
                  {displayName}
                </Text>
              </View>
            </View>

            {postImageUrl && (
              <ImageRN
                style={{
                  width: '100%',
                  height: 168,
                  borderRadius: 8,
                  marginTop: 10,
                }}
                source={{uri: postImageUrl}}
                onLoad={() => setIsRendered(true)}
                accessibilityIgnoresInvertColors={true}
              />
            )}

            <View style={styles.content}>
              <View style={[a.absolute, {top: 0, left: 0}]}>
                <QuotationIcon />
              </View>
              <Text
                ref={titleRef}
                // numberOfLines={2}
                ellipsizeMode="tail"
                style={styles.titleText}>
                {titleText}
              </Text>
              <Text
                ref={contentRef}
                // numberOfLines={3}
                ellipsizeMode="tail"
                style={title === '' ? styles.titleText : styles.contentText}>
                {contentText}
              </Text>
            </View>

            {!!targetText && (
              <Text
                // numberOfLines={1}
                style={{
                  width: '100%',
                  textAlign: 'left',
                  fontSize: 14,
                  fontWeight: 'bold',
                  color: '#000000',
                  marginVertical: 10,
                }}>
                {targetText}
              </Text>
            )}
            <View
              style={[
                a.w_full,
                a.flex_row,
                a.justify_between,
                a.align_center,
                {gap: 10},
              ]}>
              <View style={{flex: 1}}>
                <Text
                  style={{
                    color: '#000000',
                    fontSize: 14,
                    marginTop: 12,
                    marginBottom: 8,
                    fontWeight: '600',
                  }}>
                  <Trans>Sip and Spill together!</Trans>
                </Text>
                <LogoHorizontal />
                <Text
                  style={[
                    a.text_sm,
                    a.mt_sm,
                    {color: 'rgba(25, 25, 22, 0.6)'},
                  ]}>
                  https://sipz.io
                </Text>
              </View>
              <View style={[a.mt_xl]}>
                <ShareQRCode url={url} />
              </View>
            </View>
          </LinearGradient>
        </ViewShot>
        <ImageRN
          style={{
            width: imageDimensions.width,
            height: imageDimensions.height,
            // borderRadius: 16,
          }}
          source={{uri: shareImageUri}}
          accessibilityIgnoresInvertColors={true}
        />
      </View>

      <View style={styles.ctrl}>
        {shareImageUri && (
          <Text style={{color: t.palette.white}}>
            {isMobile ? (
              <Trans>Long press to share the image</Trans>
            ) : (
              <Trans>Right-click to copy the image</Trans>
            )}
          </Text>
        )}
      </View>
    </View>
  )
}

function countCharacters(str: string, maxLength: number) {
  let totalCount = 0
  let result = ''
  for (const char of str) {
    if (/^[\u4e00-\u9fa5]$/.test(char)) {
      // chinese
      totalCount += 2
    } else if (/[\u3040-\u309F\u30A0-\u30FF\u4e00-\u9fa5]/.test(char)) {
      // japanese
      totalCount += 2
    } else if (
      /[\uAC00-\uD7AF\u1100-\u1112\u1161-\u1175\u11A8-\u11C2]/.test(char)
    ) {
      // isKorean
      totalCount += 2
    } else {
      totalCount += 1
    }
    if (totalCount <= maxLength) {
      result += char
    }
  }
  return result + (totalCount <= maxLength ? '' : '...')
}

const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: 'auto',
    // padding: 10,
  },
  titleText: {
    fontSize: 18,
    fontWeight: '800',
    color: '#000000',
    fontStyle: 'italic',
  },
  contentText: {
    marginTop: 15,
    fontSize: 14,
    color: '#000000',
  },
  out: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
  },
  outputBox: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 350,
    padding: 24,
    // borderRadius: 16,
    backgroundColor: 'transparent',
  },
  userInfo: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  content: {
    position: 'relative',
    marginTop: 10,
    paddingTop: 10,
    // paddingBottom: 16,
    width: '100%',
  },
  viewShot: {
    // borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  ctrl: {
    padding: 15,
    flexDirection: 'row',
    gap: 50,
    justifyContent: 'center',
  },
})
